import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import Cookies from "js-cookie";
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { selectJobCascade } from '@/api/material';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import moment from 'moment';
import { selectAssetsType, selectLocationByParameter, selectEmployeeByParameter, insertAssets } from '@/api/administration';
export default {
  components: {
    uploadImgs,
    ExclamationCircleOutlined
  },
  props: {
    addAssetVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeAddAssetVisible'],

  setup(props, context) {
    const current = ref(0);
    const steps = ref(['填写基本信息', '售后服务信息', '维修保养信息']);
    const formRef = ref();
    const formState = reactive({
      assetsName: null,
      assetsNo: null,
      assetsTypeId: null,
      specilModel: null,
      locationName: null,
      custodianName: null,
      purchaseDate: null,
      purchaseName: null,
      purchaseContractUrl: [],
      scrapDate: null,
      aftercareProvider: null,
      aftercareContact: null,
      contactPhoneOne: null,
      contactPhoneTwo: null,
      remark: null,
      maintenanceRule: null,
      maintenancePerson: null,
      date: [],
      maintenanceContractUrl: [],
      fileUrl: []
    });
    const {
      checkName,
      checkPhone,
      signFileurl,
      validateTime
    } = getFieldRules();
    const rules = {
      assetsName: [{
        required: true,
        message: '资产名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      assetsNo: [{
        required: true,
        message: '资产编号不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      assetsTypeId: [{
        required: true,
        message: '资产类别不能为空',
        trigger: 'change',
        type: 'number'
      }],
      custodianName: [{
        required: true,
        message: '资产保管人不能为空',
        trigger: 'change'
      }],
      purchaseDate: [{
        required: true,
        message: '采购日期不能为空',
        trigger: 'change'
      }],
      // aftercareProvider:[
      //   {
      //     required: true,
      //     message: '售后服务商不能为空',
      //     trigger: 'blur',
      //   },
      //   {
      //     pattern: /^[^\s]*$/,
      //     message: '禁止输入空格',
      //     trigger: 'blur',
      //   }
      // ],
      // contactPhoneOne:[
      //   {
      //     required: true,
      //     trigger: 'blur',
      //     validator: checkPhone,
      //   },
      // ],
      maintenanceRule: [{
        required: true,
        message: '维保规则不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      maintenancePerson: [{
        required: true,
        message: '维保责任人不能为空',
        trigger: 'change',
        validator: checkName
      }],
      date: [{
        required: true,
        message: '维保起止时间不能为空',
        trigger: 'change',
        type: 'array'
      }]
    };

    const next = () => {
      // current.value++;
      formRef.value.validate().then(() => {
        // console.log(formState)
        current.value++;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const prev = () => {
      current.value--;
    }; //资产类别


    const assetsTypeData = ref([]);

    const getAssetsType = async () => {
      let res = await selectAssetsType({
        belongProject: Number(Cookies.get("belongProject"))
      });

      if (res.code === 200) {
        assetsTypeData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.typeName,
              key: index,
              id: item.id
            };
            assetsTypeData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //岗位及人员


    const useReturnOptions = ref([]);
    const useReturnOptions1 = ref([]);

    const getJobCascade = async () => {
      const belongProject = Number(Cookies.get("belongProject"));
      let res = await selectJobCascade({
        belongProject
      });

      if (res.code === 200) {
        // console.log(res)
        const jobProjectsAll = res.data.jobProjectsAll;
        useReturnOptions.value = conversion(jobProjectsAll);
        useReturnOptions1.value = conversion(jobProjectsAll);
      }
    };

    const conversion = val => {
      const arr = [];
      val.forEach(item => {
        if (item.hyEmployees.length) {
          item.hyEmployees.forEach(ixt => {
            arr.push({
              value: ixt.userName,
              label: ixt.userName
            });
          });
        }
      });
      return uniqueJsonArray(arr, 'label');
    }; //去重


    const uniqueJsonArray = (array, key) => {
      for (var i = 0; i < array.length; i++) {
        for (var j = array.length - 1; j > 0; j--) {
          if (array[i][key] == array[j][key] && i != j) {
            array.splice(j, 1);
          }
        }
      }

      return array;
    }; //获取附件的url


    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      formState.purchaseContractUrl = val;
    };

    const getAdjuncts1 = val => {
      console.log('getAdjuncts1', val);
      formState.maintenanceContractUrl = val;
    };

    const handleClose = () => {
      formRef.value.resetFields();
      context.emit('closeAddAssetVisible', false);
    };

    const loading = ref(false);

    const handleComplete = () => {
      formRef.value.validate().then(async () => {
        // console.log(formState)
        // current.value++;
        let param = {};
        Object.assign(param, formState);
        param.purchaseContractUrl = JSON.stringify(param.purchaseContractUrl);
        param.maintenanceContractUrl = JSON.stringify(param.maintenanceContractUrl);
        param.fileUrl = JSON.stringify(param.fileUrl);
        param.maintenanceStartTime = param.date[0];
        param.maintenanceEndTime = param.date[1];
        loading.value = true;

        try {
          let res = await insertAssets(param);

          if (res.code === 200) {
            loading.value = false;

            _message.success(res.message);

            context.emit('closeAddAssetVisible', false);
          } else {
            loading.value = false;

            _message.error(res.message);
          }
        } catch (error) {
          loading.value = false;
        }
      }).catch(error => {
        console.log('error', error);
      });
    }; //存放地址


    const physicsLocationData = ref([]);

    const getPhysicsLocationData = async val => {
      console.log(val);
      let res = await selectLocationByParameter({
        belongProject: Number(Cookies.get("belongProject")),
        methodType: 6,
        physicsLocation: val
      });

      if (res.code === 200) {
        physicsLocationData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.physicsLocation,
              label: item.physicsLocation
            };
            physicsLocationData.value.push(obj);
          }); //如果当前返回的数据中没有当前的值，则把当前值放进去

          let bool = res.data.some(ixt => ixt.physicsLocation === val);

          if (!bool && val) {
            physicsLocationData.value.unshift({
              value: val,
              label: val
            });
          }
        } else if (res.data.length === 0 && val) {
          physicsLocationData.value.push({
            value: val,
            text: val
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //人员


    const personnelData = ref([]);

    const getPersonnelData = async val => {
      let res = await selectEmployeeByParameter({
        belongProject: Number(Cookies.get("belongProject")),
        userName: val
      });

      if (res.code === 200) {
        personnelData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.userName,
              label: item.userName
            };
            personnelData.value.push(obj);
          }); //如果当前返回的数据中没有当前的值，则把当前值放进去

          let bool = res.data.some(ixt => ixt.userName === val);

          if (!bool && val) {
            personnelData.value.unshift({
              value: val,
              label: val
            });
          }
        } else if (res.data.length === 0 && val) {
          personnelData.value.push({
            value: val,
            text: val
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const disabledDate = current => {
      return current && current > moment().endOf('day');
    }; //获取附件的url


    const getFileUrl = val => {
      formState.fileUrl = val;
    };

    onMounted(() => {
      getAssetsType();
      getJobCascade();
    });
    return {
      current,
      steps,
      next,
      prev,
      formRef,
      formState,
      rules,
      assetsTypeData,
      useReturnOptions,
      getAdjuncts,
      handleClose,
      getAdjuncts1,
      useReturnOptions1,
      handleComplete,
      physicsLocationData,
      getPhysicsLocationData,
      personnelData,
      getPersonnelData,
      disabledDate,
      loading,
      getFileUrl
    };
  }

};