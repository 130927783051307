import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { ref, reactive, onMounted } from 'vue';
import useTablePagination from '@/hooks/pagination';
import { VerticalAlignBottomOutlined } from '@ant-design/icons-vue';
import { selectJobCascade } from '@/api/material';
import { selectDepartmentList } from '@/api/personnel';
import { selectAssetsType, selectAssetUsedByPagination } from '@/api/administration';
import Cookies from "js-cookie";
const columns = [{
  title: '类型',
  dataIndex: 'orderType',
  slots: {
    customRender: 'orderType'
  },
  width: 80,
  align: 'center',
  fixed: 'left'
}, {
  title: '领用/归还单号',
  dataIndex: 'useReturnNo',
  width: 200,
  align: 'center',
  fixed: 'left'
}, {
  title: '资产名称',
  dataIndex: 'assetsName',
  width: 120,
  align: 'center',
  fixed: 'left'
}, {
  title: '资产编号',
  dataIndex: 'assetsNo',
  width: 200,
  align: 'center'
}, {
  title: '资产类型',
  dataIndex: 'assetsTypeName',
  width: 120,
  align: 'center'
}, {
  title: '规格型号',
  dataIndex: 'specilModel',
  width: 180
}, {
  title: '领用/归还时间',
  dataIndex: 'useReturnTime',
  width: 140,
  align: 'center'
}, {
  title: '领用/归还人',
  dataIndex: 'userName',
  width: 120,
  align: 'center'
}, {
  title: '领用/归还部门',
  dataIndex: 'departmentName',
  width: 130,
  align: 'center'
}, {
  title: '经办人',
  dataIndex: 'useReturnOperatorName',
  width: 100,
  align: 'center'
}, {
  title: '备注',
  dataIndex: 'useReturnRemark',
  width: 200,
  slots: {
    customRender: 'useReturnRemark'
  }
}];
export default {
  components: {
    VerticalAlignBottomOutlined
  },
  emits: ['closeAssetUseDetail'],

  setup(props, context) {
    const handleBack = () => {
      context.emit('closeAssetUseDetail', false);
    };

    const formState = reactive({
      orderType: null,
      useReturnNo: null,
      assetsName: null,
      assetsNo: null,
      assetsTypeId: null,
      specilModel: null,
      date: [],
      uid: null,
      departmentId: null,
      useReturnOperator: null
    });
    const formRef = ref(); //表格

    const tableData = ref([]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const tableState = reactive({
      selectedRowKeys: [],
      tableLoading: false
    });

    const getTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        orderType: formState.orderType,
        useReturnNo: formState.useReturnNo,
        assetsName: formState.assetsName,
        assetsNo: formState.assetsNo,
        assetsTypeId: formState.assetsTypeId,
        specilModel: formState.specilModel,
        startTime: formState.date.length ? formState.date[0] : null,
        endTime: formState.date.length ? formState.date[1] : null,
        uid: formState.uid,
        departmentId: formState.departmentId,
        useReturnOperator: formState.useReturnOperator
      };
      tableState.tableLoading = true;

      try {
        let res = await selectAssetUsedByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          tableState.tableLoading = false;
        } else {
          tableState.tableLoading = false;

          _message.error(res.message);
        }
      } catch {
        tableState.tableLoading = false;
      }
    }; //点击查询


    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    };

    const belongProject = Number(Cookies.get("belongProject")); //资产类别

    const assetsTypeData = ref([]);

    const getAssetsType = async () => {
      let res = await selectAssetsType({
        belongProject
      });

      if (res.code === 200) {
        assetsTypeData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.typeName,
              key: index,
              id: item.id
            };
            assetsTypeData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //岗位及人员


    const useReturnOptions = ref([]);

    const getJobCascade = async () => {
      const belongProject = Number(Cookies.get("belongProject"));
      let res = await selectJobCascade({
        belongProject
      });

      if (res.code === 200) {
        // console.log(res)
        const jobProjectsAll = res.data.jobProjectsAll;
        useReturnOptions.value = conversion(jobProjectsAll);
      }
    };

    const conversion = val => {
      const arr = [];
      let myMap = {};
      val.forEach(item => {
        // let obj = {
        //   value: item.departmentId,
        //   label: item.departmentName,
        //   children: []
        // }
        if (item.hyEmployees.length) {
          item.hyEmployees.forEach(ixt => {
            let obj = {
              value: ixt.uid,
              label: ixt.userName,
              departmentId: ixt.departmentId
            };

            if (!myMap[ixt.departmentId]) {
              let obj1 = {
                value: ixt.departmentId,
                label: ixt.departmentName,
                children: []
              };
              obj1.children.push(obj);
              arr.push(obj1);
              myMap[ixt.departmentId] = ixt.departmentId;
            } else {
              // console.log('arr',arr)
              arr.forEach(ixts => {
                if (ixts.value === ixt.departmentId) {
                  ixts.children.push(obj);
                }
              });
            } // arr.push({...ixt})

          });
        } // arr.push(obj)

      }); // console.log(arr)

      return arr;
    };

    const handleLing = val => {
      // console.log(11111111,val)
      formState.uid = val ? val[1] : null; // console.log(formState.uid)

      formState.departmentId = val ? val[0] : null;
    };

    const handleLing1 = val => {
      formState.useReturnOperator = val ? val[1] : null;
    }; //部门信息


    const departmentData = ref([]);

    const getDepartmentList = async () => {
      let res = await selectDepartmentList();

      if (res.code === 200) {
        departmentData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.id,
              label: item.departmentName
            };
            departmentData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getTableData();
      getAssetsType();
      getJobCascade();
      getDepartmentList();
    });
    return {
      handleBack,
      columns,
      tableData,
      loading,
      onPageChange,
      paginationConfig,
      formState,
      formRef,
      onSubmit,
      resetForm,
      belongProject,
      assetsTypeData,
      useReturnOptions,
      handleLing,
      departmentData,
      handleLing1
    };
  }

};