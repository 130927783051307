import "ant-design-vue/es/modal/style/css";
import _Modal from "ant-design-vue/es/modal";
import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick, createVNode } from 'vue';
import useTablePagination from '@/hooks/pagination';
import addAssetModal from './components/addAssetModal.vue';
import assetUseDetailList from './components/assetUseDetailList.vue';
import assetDetail from './components/assetDetail.vue';
import setAssetModal from './components/setAssetModal.vue';
import Cookies from "js-cookie";
import { selectVerifierSetting, selectJobCascade } from '@/api/material';
import { selectBelongContract, updateTravelRevokeApply } from '@/api/officeApi';
import { selectPlanByPagination } from '@/api/production';
import { useStore } from 'vuex';
import { QuestionCircleOutlined, SettingOutlined, ExclamationCircleOutlined, ProfileOutlined, TableOutlined } from '@ant-design/icons-vue';
import { selectDepartmentList } from '@/api/personnel';
import uploadExl from '@/components/uploadExl.vue';
import { selectAssetsType, selectAssetsByPagination, addOrUpdateAssetsType, deleteAssetsType, selectLocationByParameter, updateUseOrReturn, updateMaintenance, deleteAssets } from '@/api/administration';
import { cloneDeep } from 'lodash-es';
import moment from 'moment'; //表头

const columns = [{
  title: '资产名称',
  dataIndex: 'assetsName',
  fixed: 'left',
  width: 120,
  align: 'center'
}, {
  title: '资产编号',
  dataIndex: 'assetsNo',
  fixed: 'left',
  width: 200,
  align: 'center'
}, {
  // title: '资产类别',
  dataIndex: 'assetsTypeName',
  slots: {
    title: 'customTitle',
    customRender: 'assetsTypeName'
  },
  width: 120,
  align: 'center'
}, {
  title: '规格型号',
  dataIndex: 'specilModel',
  width: 180
}, {
  title: '存放地址',
  dataIndex: 'locationName',
  width: 180
}, {
  title: '资产保管人',
  dataIndex: 'custodianName',
  width: 120,
  align: 'center'
}, {
  title: '采购日期',
  dataIndex: 'purchaseDate',
  width: 120,
  align: 'center'
}, {
  title: '报废日期',
  dataIndex: 'scrapDate',
  slots: {
    customRender: 'scrapDate'
  },
  width: 120,
  align: 'center'
}, {
  title: '状态',
  dataIndex: 'assetsState',
  slots: {
    customRender: 'assetsState'
  },
  width: 80,
  align: 'center'
}, {
  title: '领用人',
  dataIndex: 'useReturnName',
  slots: {
    customRender: 'useReturnName'
  },
  width: 120,
  align: 'center'
}, {
  title: '领用部门',
  dataIndex: 'departmentName',
  slots: {
    customRender: 'departmentName'
  },
  width: 120,
  align: 'center'
}, {
  title: '操作',
  dataIndex: 'operation',
  width: 250,
  slots: {
    customRender: 'operation'
  }
}];
const columnsDistribute = [{
  title: '资产名称',
  dataIndex: 'assetsName'
}, {
  title: '资产编号',
  dataIndex: 'assetsNo'
}, {
  title: '资产类别',
  dataIndex: 'assetsTypeName'
}, {
  title: '规格型号',
  dataIndex: 'specilModel'
}, {
  title: '存放地址',
  dataIndex: 'locationName',
  slots: {
    customRender: 'locationName'
  }
}];
export default defineComponent({
  components: {
    addAssetModal,
    assetUseDetailList,
    assetDetail,
    QuestionCircleOutlined,
    SettingOutlined,
    ExclamationCircleOutlined,
    uploadExl,
    ProfileOutlined,
    setAssetModal
  },

  setup() {
    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);
    const formRef = ref();
    const formState = reactive({
      assetsName: null,
      assetsNo: null,
      assetsTypeId: null,
      specilModel: null,
      physicsLocation: null,
      assetsState: null,
      uid: null,
      departmentId: null,
      data: []
    }); //点击查询

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    }; //表格


    const tableData = ref([]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const getTableData = async () => {
      const {
        data
      } = formState;
      const [startTime, endTime] = data.map((item, index) => {
        if (index) {
          return item.endOf('day').format('YYYY-MM-DD');
        }

        return item.startOf('day').format('YYYY-MM-DD');
      });
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        assetsName: formState.assetsName ? formState.assetsName : null,
        assetsNo: formState.assetsNo ? formState.assetsNo : null,
        assetsTypeId: formState.assetsTypeId ? formState.assetsTypeId : null,
        specilModel: formState.specilModel ? formState.specilModel : null,
        physicsLocation: formState.physicsLocation ? formState.physicsLocation : null,
        assetsState: formState.assetsState,
        uid: formState.uid ? formState.uid : null,
        departmentId: formState.departmentId ? formState.departmentId : null,
        startTime: startTime ? startTime : null,
        endTime: endTime ? endTime : null
      };
      loading.value = true;

      try {
        let res = await selectAssetsByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];

          if (res.data.list.length) {
            res.data.list.forEach(item => {
              arr.push({ ...item,
                key: item.id
              });
            });
          }

          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loading.value = false;
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch (error) {
        loading.value = false;
      }
    }; //部门信息


    const departmentData = ref([]);

    const getDepartmentList = async () => {
      let res = await selectDepartmentList();

      if (res.code === 200) {
        departmentData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.id,
              label: item.departmentName
            };
            departmentData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //存放地址


    const physicsLocationData = ref([]);

    const getPhysicsLocationData = async () => {
      let res = await selectLocationByParameter({
        belongProject,
        methodType: 6,
        physicsLocation: null
      });

      if (res.code === 200) {
        physicsLocationData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.physicsLocation,
              label: item.physicsLocation
            };
            physicsLocationData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const belongProject = Number(Cookies.get("belongProject")); //资产类别

    const assetsTypeData = ref([]);

    const getAssetsType = async () => {
      let res = await selectAssetsType({
        belongProject
      });

      if (res.code === 200) {
        assetsTypeData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.typeName,
              key: item.typeName,
              id: item.id
            };
            assetsTypeData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //岗位及人员


    const useReturnOptions = ref([]);

    const getJobCascade = async () => {
      const belongProject = Number(Cookies.get("belongProject"));
      let res = await selectJobCascade({
        belongProject
      });

      if (res.code === 200) {
        // console.log(res)
        const jobProjectsAll = res.data.jobProjectsAll;
        useReturnOptions.value = conversion(jobProjectsAll);
      }
    };

    const conversion = val => {
      const arr = [];
      let myMap = {};
      val.forEach(item => {
        // let obj = {
        //   value: item.departmentId,
        //   label: item.departmentName,
        //   children: []
        // }
        if (item.hyEmployees.length) {
          item.hyEmployees.forEach(ixt => {
            let obj = {
              value: ixt.uid,
              label: ixt.userName,
              departmentId: ixt.departmentId
            };

            if (!myMap[ixt.departmentId]) {
              let obj1 = {
                value: ixt.departmentId,
                label: ixt.departmentName,
                children: []
              };
              obj1.children.push(obj);
              arr.push(obj1);
              myMap[ixt.departmentId] = ixt.departmentId;
            } else {
              // console.log('arr',arr)
              arr.forEach(ixts => {
                if (ixts.value === ixt.departmentId) {
                  ixts.children.push(obj);
                }
              });
            } // arr.push({...ixt})

          });
        } // arr.push(obj)

      }); // console.log(arr)

      return arr;
    };

    const tableState = reactive({
      selectedRowKeys: []
    });

    const onSelectChange = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      tableState.selectedRowKeys = selectedRowKeys;
    };

    const hasSelected = computed(() => tableState.selectedRowKeys.length > 0); //资产类别管理

    const assetTypeVisible = ref(false);

    const handleGetssetType = async () => {
      assetTypeVisible.value = true;
    };

    const editableDataAssetType = reactive({});

    const editAssetType = key => {
      editableDataAssetType[key] = cloneDeep(assetsTypeData.value.filter(item => key === item.key)[0]);
    };

    const saveAssetType = async key => {
      editableDataAssetType[key].label = editableDataAssetType[key].label.replace(/\s*/g, "");

      if (!editableDataAssetType[key].label) {
        return _message.error('资产类别名称不能为空');
      }

      try {
        let param = {
          id: editableDataAssetType[key].id,
          typeName: editableDataAssetType[key].label
        };
        let res = await addOrUpdateAssetsType(param);

        if (res.code === 200) {
          assetsTypeData.value.forEach(item => {
            if (item.key === key) {
              item.id = res.data.id;
              item.label = res.data.typeName;
              item.key = res.data.itemName;
            }
          });

          _message.success(res.message);

          delete editableDataAssetType[key];
        } else {
          _message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const cancelAssetType = key => {
      delete editableDataAssetType[key];
    };

    const handleAddAssetType = () => {
      assetsTypeData.value.push({
        label: "",
        key: assetsTypeData.value.length,
        id: null
      });
      editableDataAssetType[assetsTypeData.value.length - 1] = {
        label: "",
        key: assetsTypeData.value.length,
        id: null
      };
      console.log(assetsTypeData.value);
    };

    const deletelAssetType = async val => {
      if (val.id === null) {
        delete editableDataAssetType[val.key];
        let inx = assetsTypeData.value.findIndex(item => item.key === val.key);
        assetsTypeData.value.splice(inx, 1);
      } else {
        const res = await deleteAssetsType({
          id: val.id
        });

        if (res.code === 200) {
          _message.success(res.message);

          let inx = assetsTypeData.value.findIndex(item => item.id === val.id);
          assetsTypeData.value.splice(inx, 1);
          delete editableDataAssetType[val.key];
        } else {
          _message.error(res.message);
        }
      }
    };

    const handleClosePayable = () => {
      if (Object.keys(editableDataAssetType).length) {
        _Modal.confirm({
          title: '当前页存在未保存信息，确认放弃更改吗?',
          icon: createVNode(ExclamationCircleOutlined),

          onOk() {
            Object.keys(editableDataAssetType).map(key => delete editableDataAssetType[key]);
            getAssetsType();
            assetTypeVisible.value = false;
          },

          // onCancel() {
          //   console.log('取消');
          // },
          class: 'test'
        });
      } else {
        getAssetsType();
        assetTypeVisible.value = false;
      }
    }; //添加资产


    const addAssetVisible = ref(false);

    const handleAddAsset = () => {
      addAssetVisible.value = true;
    };

    const closeAddAssetVisible = val => {
      addAssetVisible.value = false;
      getTableData();
    };

    const handleLing = val => {
      // console.log(11111111,val)
      formState.uid = val ? val[1] : null; // console.log(formState.uid)

      formState.departmentId = val ? val[0] : null;
    };

    const isSuccess = val => {
      getTableData();
    };

    const assetPage = ref(1);

    const closeAssetUseDetail = () => {
      assetPage.value = 1;
    }; //详情


    let assetDetailId = ref(null);

    const closeAssetDetail = () => {
      assetPage.value = 1;
    };

    const toView = async id => {
      assetDetailId.value = id;
      assetPage.value = 3;
    }; //派发/归还


    const distributeTitle = ref('派发');
    const distributeVisible = ref(false);
    const distributeInfo = reactive({
      uid: null,
      useReturnTime: moment().startOf('day').format('YYYY-MM-DD'),
      useReturnOperator: null,
      useReturnRemark: null,
      tableData: []
    });

    const toDistribute = (num, val, type) => {
      useReturnOptions.value.forEach(item => {
        item.children.forEach(ixt => {
          if (ixt.value === Cookies.get('uid')) {
            distributeInfo.useReturnOperator = [item.value, ixt.value];
          }
        });
      });
      distributeVisible.value = true;
      distributeTitle.value = type === 0 ? '派发' : '归还';

      if (num === 1) {
        distributeInfo.tableData = [val];
      } else {
        distributeInfo.tableData = [];
        tableState.selectedRowKeys.forEach(item => {
          if (tableData.value.find(ixt => ixt.key === item).assetsState === type) {
            distributeInfo.tableData.push(tableData.value.find(ixt => ixt.key === item));
          }
        });

        if (!distributeInfo.tableData.length) {
          distributeVisible.value = false;

          _message.error(`所选择项中无${distributeTitle.value}项`);
        }
      }
    };

    const formRefForDistribute = ref();
    const rules = {
      uid: [{
        required: true,
        message: '领用人不能为空',
        trigger: 'change'
      }],
      departmentId: [{
        required: true,
        message: '所在部门不能为空',
        trigger: 'change',
        type: 'number'
      }],
      useReturnTime: [{
        required: true,
        message: '领用时间不能为空',
        trigger: 'change'
      }],
      useReturnOperator: [{
        required: true,
        message: '经办人不能为空',
        trigger: 'change',
        type: 'array'
      }],
      maintenanceTime: [{
        required: true,
        message: '维保时间不能为空',
        trigger: 'change'
      }],
      maintenanceUid: [{
        required: true,
        message: '维保上报人不能为空',
        trigger: 'change'
      }],
      maintenanceDecribed: [{
        required: true,
        message: '维保描述不能为空',
        trigger: 'blur'
      }]
    };

    const handleLingDistribute = val => {
      distributeInfo.uid = val ? val[1] : null;
      distributeInfo.departmentId = val ? val[0] : null;
    }; // const handleLingDistribute1 = (val) => {
    //   console.log(val)
    //   if(val) {
    //     useReturnOptions.value.forEach(item => {
    //       if(item.value === val[0]) {
    //         item.children.forEach(ixt => {
    //           if(ixt.value === val[1]) {
    //             distributeInfo.useReturnOperator = ixt.label
    //           }
    //         })
    //       }
    //     })
    //   }
    // }


    const handleCloseDistributeInfo = () => {
      formRefForDistribute.value.resetFields();
      distributeVisible.value = false;
    };

    const distributeLoading = ref(false);

    const handleDistributeInfo = () => {
      formRefForDistribute.value.validate().then(async () => {
        let ids = [];
        distributeInfo.tableData.forEach(item => {
          ids.push(item.id);
        });
        let param = {
          ids: ids.join(','),
          uid: distributeInfo.uid,
          useReturnTime: distributeInfo.useReturnTime,
          useReturnOperator: distributeInfo.useReturnOperator[1],
          useReturnRemark: distributeInfo.useReturnRemark,
          orderType: distributeTitle.value === '派发' ? 1 : 0,
          assetList: null
        };
        let arr = [];

        if (distributeTitle.value === '归还') {
          if (distributeInfo.tableData.length) {
            distributeInfo.tableData.forEach(item => {
              arr.push({
                id: item.id,
                physicsLocation: item.locationName
              });
            });
          }

          param.assetList = arr;
        }

        distributeLoading.value = true;

        try {
          let res = await updateUseOrReturn(param);

          if (res.code === 200) {
            distributeLoading.value = false;

            _message.success(res.message);

            formRefForDistribute.value.resetFields();
            distributeVisible.value = false;
            getTableData();
          } else {
            distributeLoading.value = false;

            _message.error(res.message);
          }
        } catch (error) {
          distributeLoading.value = false;
        }
      }).catch(error => {
        console.log('error', error);
      });
    }; //存放地址


    const physicsLocationData1 = ref([]);

    const getPhysicsLocationData1 = async val => {
      console.log(val);
      let res = await selectLocationByParameter({
        belongProject: Number(Cookies.get("belongProject")),
        methodType: 6,
        physicsLocation: val
      });

      if (res.code === 200) {
        physicsLocationData1.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.physicsLocation,
              label: item.physicsLocation
            };
            physicsLocationData1.value.push(obj);
          }); //如果当前返回的数据中没有当前的值，则把当前值放进去

          let bool = res.data.some(ixt => ixt.physicsLocation === val);

          if (!bool && val) {
            physicsLocationData1.value.unshift({
              value: val,
              label: val
            });
          }
        } else if (res.data.length === 0 && val) {
          physicsLocationData1.value.push({
            value: val,
            text: val
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //维保


    const maintenanceVisible = ref(false);
    const maintenanceRef = ref();
    const maintenanceInfo = reactive({
      id: null,
      maintenanceUid: null,
      maintenanceTime: moment().startOf('day').format('YYYY-MM-DD'),
      maintenanceDecribed: null
    });

    const toMaintenance = id => {
      maintenanceInfo.id = id;
      maintenanceVisible.value = true;
    };

    const handleLingMaintenance = val => {
      maintenanceInfo.maintenanceUid = val ? val[1] : null;
      maintenanceInfo.departmentId = val ? val[0] : null;
    };

    const handleCloseMaintenanceInfo = () => {
      maintenanceRef.value.resetFields();
      maintenanceVisible.value = false;
    };

    const maintenanceInfoLoading = ref(false);

    const handleMaintenanceInfo = () => {
      maintenanceRef.value.validate().then(async () => {
        let param = {};
        Object.assign(param, maintenanceInfo);
        maintenanceInfoLoading.value = true;

        try {
          let res = await updateMaintenance(param);

          if (res.code === 200) {
            maintenanceInfoLoading.value = false;

            _message.success(res.message);

            maintenanceRef.value.resetFields();
            maintenanceVisible.value = false;
            getTableData();
          } else {
            maintenanceInfoLoading.value = false;

            _message.error(res.message);
          }
        } catch (error) {
          maintenanceInfoLoading.value = false;
        }
      }).catch(error => {
        console.log('error', error);
      });
    }; //修改


    let modificationId = ref(null);
    const modificationVisible = ref(false);

    const toModification = id => {
      modificationId.value = id;
      modificationVisible.value = true;
    };

    const closeSetAssetVisible = () => {
      modificationVisible.value = false;
      getTableData();
    }; //删除资产清单


    const deletelAssetList = async val => {
      const res = await deleteAssets({
        id: val.id
      });

      if (res.code === 200) {
        _message.success(res.message);

        getTableData();
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getTableData();
      getDepartmentList();
      getAssetsType();
      getJobCascade();
      getPhysicsLocationData();
    });
    return {
      formRef,
      formState,
      onSubmit,
      resetForm,
      paginationConfig,
      columns,
      tableData,
      loading,
      onPageChange,
      toView,
      departmentData,
      assetsTypeData,
      useReturnOptions,
      ...toRefs(tableState),
      onSelectChange,
      hasSelected,
      assetTypeVisible,
      handleGetssetType,
      assetTypeVisible,
      handleGetssetType,
      editableDataAssetType,
      editAssetType,
      saveAssetType,
      cancelAssetType,
      handleAddAssetType,
      deletelAssetType,
      handleClosePayable,
      handleAddAsset,
      addAssetVisible,
      closeAddAssetVisible,
      handleLing,
      physicsLocationData,
      isSuccess,
      belongProject,
      closeAssetUseDetail,
      assetPage,
      closeAssetDetail,
      assetDetailId,
      toDistribute,
      distributeVisible,
      distributeInfo,
      formRefForDistribute,
      rules,
      handleLingDistribute,
      // handleLingDistribute1,
      columnsDistribute,
      handleCloseDistributeInfo,
      handleDistributeInfo,
      distributeLoading,
      distributeTitle,
      physicsLocationData1,
      getPhysicsLocationData1,
      toMaintenance,
      maintenanceVisible,
      maintenanceInfo,
      maintenanceRef,
      handleLingMaintenance,
      handleCloseMaintenanceInfo,
      handleMaintenanceInfo,
      maintenanceInfoLoading,
      toModification,
      modificationId,
      modificationVisible,
      closeSetAssetVisible,
      deletelAssetList
    };
  }

});