import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { ref, reactive, onMounted } from 'vue';
import { VerticalAlignBottomOutlined, ProfileOutlined } from '@ant-design/icons-vue';
import { selectAssetsById, selectAssetUsedById, selectMaintenanceById } from '@/api/administration';
import Cookies from "js-cookie";
const columns = [{
  title: '类型',
  dataIndex: 'orderType',
  slots: {
    customRender: 'orderType'
  }
}, {
  title: '领用/归还单号',
  dataIndex: 'useReturnNo'
}, {
  title: '领用/归还时间',
  dataIndex: 'useReturnTime'
}, {
  title: '领用/归还人',
  dataIndex: 'userName'
}, {
  title: '所在部门',
  dataIndex: 'departmentName'
}, {
  title: '经办人',
  dataIndex: 'useReturnOperatorName'
}, {
  title: '备注',
  dataIndex: 'useReturnRemark'
}];
const columns1 = [{
  title: '维保单号',
  dataIndex: 'maintenanceNo'
}, {
  title: '维保时间',
  dataIndex: 'maintenanceTime'
}, {
  title: '维保上报人',
  dataIndex: 'maintenanceUserName'
}, {
  title: '所在部门',
  dataIndex: 'departmentName'
}, {
  title: '维保描述',
  dataIndex: 'maintenanceDecribed',
  width: '500px'
}];
export default {
  components: {
    VerticalAlignBottomOutlined,
    ProfileOutlined
  },
  props: {
    assetDetailId: {
      type: Number,
      default: null
    }
  },
  emits: ['closeAssetDetail'],

  setup(props, context) {
    const handleBack = () => {
      context.emit('closeAssetDetail', false);
    };

    const activeKey = ref('1');
    const detailInfo = reactive({
      assetsName: null,
      assetsNo: null,
      assetsTypeName: null,
      specilModel: null,
      locationName: null,
      custodianName: null,
      purchaseDate: null,
      purchaseName: null,
      purchaseContractUrl: [],
      scrapDate: null,
      aftercareProvider: null,
      aftercareContact: null,
      contactPhoneOne: null,
      contactPhoneTwo: null,
      remark: null,
      maintenanceRule: null,
      maintenancePerson: null,
      maintenanceStartTime: null,
      maintenanceEndTime: null,
      maintenanceContractUrl: [],
      fileUrl: []
    });

    const getDetailInfo = async () => {
      let res = await selectAssetsById({
        id: props.assetDetailId
      });

      if (res.code === 200) {
        Object.assign(detailInfo, res.data);
        detailInfo.purchaseContractUrl = JSON.parse(res.data.purchaseContractUrl);
        detailInfo.fileUrl = res.data.fileUrl ? JSON.parse(res.data.fileUrl) : [];
        detailInfo.maintenanceContractUrl = JSON.parse(res.data.maintenanceContractUrl);
      } else {
        _message.error(res.message);
      }
    };

    const useTableData = ref([]);
    const maintenanceTableData = ref([]);

    const handleChangeTab = async val => {
      // activeKey.value = val
      if (val == 2) {
        let res = await selectAssetUsedById({
          id: props.assetDetailId
        });

        if (res.code === 200) {
          useTableData.value = [];

          if (res.data.list && res.data.list.length) {
            useTableData.value = res.data.list;
          }

          activeKey.value = val;
        } else {
          _message.error(res.message);
        }
      } else if (val == 3) {
        let res = await selectMaintenanceById(props.assetDetailId);

        if (res.code === 200) {
          maintenanceTableData.value = [];

          if (res.data.list && res.data.list.length) {
            maintenanceTableData.value = res.data.list;
          }

          activeKey.value = val;
        } else {
          _message.error(res.message);
        }
      } else {
        activeKey.value = val;
      }
    };

    onMounted(() => {
      getDetailInfo();
    });
    return {
      handleBack,
      columns,
      activeKey,
      detailInfo,
      getDetailInfo,
      handleChangeTab,
      useTableData,
      columns1,
      maintenanceTableData
    };
  }

};